import React, { Suspense } from "react";
import "./assets/css/tailwind.output.css";
import App from "./App";
import ThemedSuspense from "./components/ThemedSuspense";
import MainContext from "./context/MainContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRoot } from "react-dom/client";
import "./assets/css/style.css";

//
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // staleTime: 1 * 60 * 1000,
    },
  },
});

const root = createRoot(document.getElementById("root"));

root.render(
  <QueryClientProvider client={queryClient}>
    <MainContext>
      <Suspense>
        <App />
      </Suspense>
    </MainContext>
  </QueryClientProvider>
);
