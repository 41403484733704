import React, { lazy, useContext, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { stateContext } from "./context/MainContext";
import Spinner from "./components/Spinners/Spinner";
import {
  MantineProvider,
  createEmotionCache,
  AppShell,
  Navbar,
  Header,
  MediaQuery,
  Burger,
} from "@mantine/core";
import stylisRTLPlugin from "stylis-plugin-rtl";
import { theme } from "./layout/theme";

const HeaderComponent = lazy(() => import("./layout/HeaderComponent"));
const SidebarComponent = lazy(() =>
  import("./components/Sidebar/SidebarComponent")
);
const Layout = lazy(() => import("./layout/Layout"));
const Login = lazy(() => import("./pages/Login"));

function App() {
  const { token, isLoading, dark, setDark } = useContext(stateContext);
  const rtlCache = createEmotionCache({
    key: "mantine-rtl",
    stylisPlugins: [stylisRTLPlugin],
  });
  const useTheme = theme(dark);
  const [openNavbar, setOpenNavbar] = useState(false);

  //
  return (
    <div className="app">
      {isLoading && <Spinner />}
      <Router>
        <MantineProvider
          emotionCache={rtlCache}
          withGlobalStyles
          withNormalizeCSS
          theme={useTheme}
        >
          <AppShell
            navbarOffsetBreakpoint="md"
            navbar={
              token && (
                <Navbar
                  width={{ sm: 226, lg: 226 }}
                  hiddenBreakpoint="md"
                  hidden={!openNavbar}
                >
                  <SidebarComponent
                    openNavbar={openNavbar}
                    setOpenNavbar={setOpenNavbar}
                  />
                </Navbar>
              )
            }
            header={
              token && (
                <Header className="header" height={70}>
                  <MediaQuery largerThan="md" styles={{ display: "none" }}>
                    <Burger
                      opened={openNavbar}
                      onClick={() => setOpenNavbar((o) => !o)}
                      size="md"
                      mr="xl"
                    />
                  </MediaQuery>
                  <HeaderComponent dark={dark} setDark={setDark} />
                </Header>
              )
            }
          >
            <Route path="/">{token ? <Layout /> : <Login />}</Route>
            <Route path="/login" component={Login} />
          </AppShell>
        </MantineProvider>
      </Router>
    </div>
  );
}

export default App;
