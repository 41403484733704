//
export const theme = (dark) => {
  const theme = {
    colorScheme: dark ? "dark" : "light",
    fontFamily: "SomarSansRegular",
    components: {
      Button: {
        styles: (theme, params) => ({
          root: {
            height: 42,
            padding: "0 30px",
            backgroundColor:
              params.variant === "filled"
                ? theme.colors[params.color || theme.primaryColor][9]
                : params.variant === "project1"
                ? "#000"
                : params.variant === "project2"
                ? "#111"
                : params.variant === "project3"
                ? "#222"
                : params.variant === "project4"
                ? "#333"
                : params.variant === "project5"
                ? "#444"
                : undefined,
          },
        }),
      },
    },
  };

  return theme;
};
